import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { LightBulbOn } from 'iconoir-react'
import { twMerge } from 'tailwind-merge'
import { Tooltip } from './Tooltip'

export function Info({ title, message, className }: { title: string; message: string; className?: string }) {
  return (
    <Tooltip
      // className="pointer-events-auto"
      message={
        <div className="flex max-w-xs flex-col gap-2 p-1">
          <div className="flex items-center gap-2">
            <LightBulbOn height={16} width={16} strokeWidth={2} className="text-ui-sm text-trn-100" />
            <p className="text-ui-sm text-trn-100">{title}</p>
          </div>
          <p className="whitespace-pre-wrap text-body-xs text-trn-200">{message}</p>
        </div>
      }
    >
      <InformationCircleIcon
        shapeRendering={`auto`}
        strokeWidth={2}
        className={twMerge(`h-5 w-5 text-trn-300`, className)}
      />
    </Tooltip>
  )
}
